<template>
  <div>
    <sidebar-item
      v-if="this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase())"
      :link="{
        name: 'Notificações',
        icon: 'fas fa-paper-plane text-dark',
        path: '/crm/notificacoes',
      }"
    />

    <template>
      <!-- <li class="nav-item" v-if="this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase())">
        <a class="nav-link cursor-pointer" @click="emailCollapse = !emailCollapse">
          <i class="fas fa-envelope"></i>
          <span class="nav-link-text" style="flex-grow: 1">Email</span>
          <i
            class="text-right"
            :class="{
              'fas fa-chevron-down': emailCollapse,
              'fas fa-chevron-up': !emailCollapse,
            }"
          ></i>
        </a>
      </li> -->

      <sidebar-item
        v-if="this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase())"
        :link="{
          name: 'Modelo de email',
          icon: 'fas fa-cog text-dark',
          path: '/crm/email/listaModelos',
        }"
      />
    </template>

    <sidebar-item
      :link="{
        name: 'Integrações',
        icon: 'fas fa-cogs',
        path: '/crm/integracoes',
      }"
    />

    <sidebar-item
      :link="{
        name: 'Grupos',
        icon: 'fas fa-users',
        path: '/crm/grupos',
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'MenuCRM',
  data() {
    return {
      campanha: null,
      configuracoes: null,
      tipoUsuario: null,
      notificacaoCollapse: true,
      emailCollapse: true,
      ultimasConversas: [],
    };
  },
  async beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.tipoUsuario = this.$autenticacao.logado().tipo;

    // Verificação se ha campanha salva no local storage.
    if (this.$campanha.obterCampanhaAtual()) {
      this.campanha = this.$campanha.obterCampanhaAtual();
    } else {
      this.campanha = null;
    }
  },
  mounted: function () {
    if (this.configuracoes.MODULO_CHAT) {
      this.chat = new this.$chat();
      this.$root.$on('socketRecebido', (msg) => {
        if (this[msg.canal]) {
          this[msg.canal](msg.valor);
        }
      });
    }
  },
  methods: {
    carregaUltimasConversas: function (data) {
      this.ultimasConversas = data;
      let titulo = document.title.replace(/ *\([^)]*\) */g, '');
      if (this.notificacao > 0) {
        titulo = '(' + this.notificacao + ') ' + titulo;
      }

      document.title = titulo;
    },
  },
  computed: {
    notificacao() {
      let naoLidas = 0;
      for (let i in this.ultimasConversas) {
        if (this.ultimasConversas[i].naoVisualizadosUsuario > 0) {
          naoLidas = naoLidas + this.ultimasConversas[i].naoVisualizadosUsuario;
        }
      }
      return naoLidas;
    },
  },
};
</script>
