/**
 * Retorno padrão para chamadas à métodos e api.
 * @class {Comunicacao}
 */

const axios = require("./lib").Axios;
const Objetos = require("./lib").Helper.Objetos;

let instancia = null;
const privateProps = new WeakMap();

class Comunicacao {
    /**
     * Cria um objeto de comunicação com o WebService
     * @param {string} baseURL - URL Base
     * @param {number} timeout - timeout padrao
     * @param {string} token [optional] - token de autenticacao
     */

    constructor(baseURL, token, timeout = 30000) {
        if (instancia) return instancia;
        instancia = this;
        let options = {
            baseURL: baseURL,
            timeout: timeout,
        };
        let ax = axios.create(options);

        if (token) ax.defaults.headers.common['Authorization'] = token;

        privateProps.set(this, {
            axios: ax,
            baseURL: baseURL,
            timeout: timeout,
            token: token,
            cache: {}
        })
    }

    /**
     * @property {axios} - Retorna uma instancia do axios
     * @return {axios}
     */
    get axios() {
        return privateProps.get(this).axios;
    }

    /**
     * @property {string} - Retorna a baseURL
     * @return {string}
     */
    get baseURL() {
        return privateProps.get(this).baseURL;
    }

    /**
     * @property {number} - Retorna o timeout
     * @return {number}
     */
    get timeout() {
        return privateProps.get(this).timeout;
    }

    /**
     * @property {string} - Retorna o token
     * @return {string}
     */
    get token() {
        return privateProps.get(this).token;
    }

    /**
     * Modifica o token do objeto de conexão com o WebService
     */
    set token(val) {
        if (val) {
            privateProps.get(this).token = val;
            privateProps.get(this).axios.defaults.headers.common['Authorization'] = val;
        }
        else {
            privateProps.get(this).token = null;
            privateProps.get(this).axios.defaults.headers.common = {};
        }
    }


    /**
     * Verifica se a requisição está no Cache
     * @param {object} options - hash da requisicao
     */
    async getCache(options) {
        let cache = privateProps.get(this).cache;
        if (typeof cache[options.url] == "undefined") {
            cache[options.url] = await this.axios(options)
        }
        return cache[options.url];
    }


    /**
     * 
     * @param {string} endpoint - Endpoint de desitno
     * @param {string} method - POST, GET, PUT, DELETE
     * @param {string} data [opicional] - Corpo da requisição
     * @param {number} timeout [opicional] - timout da requisição
     * @param {boolean} cache [opicional] - timout da requisição
     */
    async send(endpoint, options = {}, timeout = null) {
        let defaulOptions = {
            method: "GET",
            data: null,
            timeout: timeout,
            cache: false,
            headers: null,
            url: endpoint
        }

        if (typeof options != "object") {
            throw new Error("Comunicacao.send() -> options is not an object");
        }
        
        options = Objetos.leftMerge(defaulOptions, options);
        options = Objetos.clean(options);
        if (options.method == "GET" && options.data) {
            let query = "";
            let i = 0;
            for (let key in options.data) {
                if (i > 0) query += "&";
                query += encodeURIComponent(key) + "=" + encodeURIComponent(options.data[key]);
                i++;
            }
            options.url += "?" + query;
        }

        try {
            let response;
            if (options.cache) {
                response = await this.getCache(options);
            } else {
                response = await this.axios(options);
            }
            return response;
        } catch (e) {
            if (!e.response) {
                let data = {
                    status: 500,
                    data: {
                        retorno: e.message
                    }
                }
                return data;
            } else {
                return e.response;
            }
        }
    }
}

export default Comunicacao;