var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('sidebar-item',{attrs:{"link":{
      name: 'Notificações',
      icon: 'fas fa-paper-plane text-dark',
      path: '/crm/notificacoes',
    }}}):_vm._e(),[(this.campanha && ['root', 'admin'].includes(this.tipoUsuario.toLowerCase()))?_c('sidebar-item',{attrs:{"link":{
        name: 'Modelo de email',
        icon: 'fas fa-cog text-dark',
        path: '/crm/email/listaModelos',
      }}}):_vm._e()],_c('sidebar-item',{attrs:{"link":{
      name: 'Integrações',
      icon: 'fas fa-cogs',
      path: '/crm/integracoes',
    }}}),_c('sidebar-item',{attrs:{"link":{
      name: 'Grupos',
      icon: 'fas fa-users',
      path: '/crm/grupos',
    }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }