import Vue from 'vue';

const initialState = () => ({
  logsRequisicao: [],
  totalLogsRequisicao: 0,
  logsImportacao: [],
  totalLogsImportacao: 0,

  carregando: false,
});

const state = initialState();

const getters = {
  getLogsRequisicao(state) {
    return state.logsRequisicao;
  },

  getTotalizadorRequisicao(state) {
    return state.totalLogsRequisicao;
  },

  getLogsImportacao(state) {
    return state.logsImportacao;
  },

  getTotalizadorImportacao(state) {
    return state.totalLogsImportacao;
  },

  getCarregando(state) {
    return state.carregando;
  },
};

const actions = {
  fetchLogsRequisicao({ commit }, { filtros, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      let url = `/trace/v1/logs/requisicao/${paginacao.porPagina}/${paginacao.pagina}`;

      var res = await comunicacao.send(url, {
        method: 'GET',
        data: filtros,
      });

      if ([200, 201].includes(res.status)) {
        commit('setLogsRequisicao', res.data.retorno.lista);
        commit('setTotalizadorRequisicao', res.data.retorno.total);

        resolve({
          message: 'Consulta realizada com sucesso',
          status: res.status,
        });
      } else {
        commit('setLogsRequisicao', []);
        commit('setTotalizadorRequisicao', 0);

        resolve({
          message: res.data.mensagem,
          status: res.status,
        });
      }

      commit('setCarregando', false);
    });
  },

  fetchLogsImportacao({ commit }, { filtros, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      let url = `/trace/v1/logs/importacao/${paginacao.porPagina}/${paginacao.pagina}`;

      var res = await comunicacao.send(url, {
        method: 'GET',
        data: filtros,
      });

      if ([200, 201].includes(res.status)) {
        commit('setLogsImportacao', res.data.retorno.lista);
        commit('setTotalizadorImportacao', res.data.retorno.total);

        resolve({
          message: 'Consulta realizada com sucesso',
          status: res.status,
        });
      } else {
        commit('setLogsImportacao', []);
        commit('setTotalizadorImportacao', 0);

        resolve({
          message: res.data.mensagem,
          status: res.status,
        });
      }

      commit('setCarregando', false);
    });
  },

  reenviarRequisicao({ commit }, { url, dados }) {
    return new Promise(async (resolve, reject) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      var res = await comunicacao.send(url, {
        method: 'POST',
        data: dados,
      });

      console.log('res', res);

      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.status,
          mensagem: 'Reenviado com sucesso',
        });
      } else {
        reject({
          status: res.data.status,
          mensagem: res.data.mensagem,
        });
      }

      commit('setCarregando', false);
    });
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setLogsRequisicao(state, data) {
    state.logsRequisicao = data;
  },

  setTotalizadorRequisicao(state, data) {
    state.totalLogsRequisicao = data;
  },

  setLogsImportacao(state, data) {
    state.logsImportacao = data;
  },

  setTotalizadorImportacao(state, data) {
    state.totalLogsImportacao = data;
  },

  setCarregando(state, data) {
    state.carregando = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
