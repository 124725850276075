import Vue from 'vue';

// Object initial state
const initialState = () => ({
  aceites: [],
  totalAceites: 0,
  bairros: [],
  consumidores: [],
  perfilConsumidor: null,
  totalConsumidores: 0,
  restricoesAssociados: [],
  totalRestritos: 0,
  carregando: false,
  erro: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getAceites(state) {
    return state.aceites;
  },

  getTotalizadorAceites(state) {
    return state.totalAceites;
  },

  getBairros(state) {
    return state.bairros;
  },

  getConsumidores(state) {
    return state.consumidores;
  },

  getConsumidoresLiterals(state) {
    let consumidores = {};
    for (let i in state.consumidores) {
      if (state.consumidores[i].cpf) {
        consumidores[state.consumidores[i].cpf] = state.consumidores[i];
      } else if (state.consumidores[i].telefone) {
        consumidores[state.consumidores[i].telefone] = state.consumidores[i];
      }
    }
    return consumidores;
  },

  getPerfilConsumidor(state) {
    return state.perfilConsumidor;
  },

  getTotalizador(state) {
    return state.totalConsumidores;
  },

  getRestricoesAssociado(state) {
    return state.restricoesAssociados;
  },

  getTotalizadorRestritos(state) {
    return state.totalRestritos;
  },

  getLoader(state) {
    return state.carregando;
  },

  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  fetchAceites({ commit }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/aceites`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if ([200].includes(res.status)) {
        commit('setAceites', res.data.retorno);
        commit('setTotalizadorAceites', res.data.retorno.length);
        commit('setErro', null);
      } else {
        commit('setTotalizadorAceites', 0);
        commit('setTotalizadorAceites', []);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }

      commit('switchCarregando', false);
      return resolve();
    });
  },

  cadastrarAceite({ commit }, { nomeTermo, obrigatorio, texto }) {
    return new Promise(async (resolve, reject) => {
      commit('switchCarregando', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/aceites`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { nomeTermo, texto, obrigatorio },
      });

      commit('switchCarregando', false);

      if ([200, 201].includes(res.status)) {
        return resolve({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: res.data.retorno,
        });
      } else {
        return reject({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: null,
        });
      }
    });
  },

  atualizarAceite({ commit }, { nomeTermo, obrigatorio, texto }) {
    return new Promise(async (resolve, reject) => {
      commit('switchCarregando', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/aceites/${nomeTermo}`;
      const res = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: { texto, obrigatorio },
      });

      commit('switchCarregando', false);

      if ([200].includes(res.status)) {
        return resolve({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: res.data.retorno,
        });
      } else {
        return reject({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: null,
        });
      }
    });
  },

  excluirAceite({ commit }, { nomeTermo }) {
    return new Promise(async (resolve, reject) => {
      commit('switchCarregando', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/aceites/${nomeTermo}`;
      const res = await comunicacao.send(endpoint, { method: 'DELETE' });

      commit('switchCarregando', false);

      if ([200].includes(res.status)) {
        return resolve({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: res.data.retorno,
        });
      } else {
        return reject({
          status: res.status,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: null,
        });
      }
    });
  },

  fetchBairros({ commit }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = '/consumidor/v1/bairros';
      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('setBairros', res.data.retorno);
      }
      commit('switchCarregando', false);
      resolve();
    });
  },

  // Método está como POST, sendo que na documentação está como GET...
  fetchConsumidores({ commit }, { filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/consumidores/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          filtro: filtro || {},
        },
      });
      if ([200, 201].includes(res.status)) {
        commit('setConsumidores', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setConsumidores', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },

  fetchConsumidoresCPF({ commit }, { cpf, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/consumidores/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: {
          filtroCpf: cpf || {},
        },
      });
      if ([200, 201].includes(res.status)) {
        commit('setConsumidores', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setConsumidores', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },

  fetchRestricoesAssociado({ commit }, { campanha, consumidor, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/campanha/v1/cpfsrestritos/${campanha.ano}/${campanha.identificacao}/consumidor/${consumidor}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, { method: 'POST' });
      if ([200, 201].includes(res.status)) {
        commit('setRestricoesAssociados', res.data.retorno.restricoes);
        commit('setTotalizadorRestritos', res.data.retorno.total);
      } else if ([404].includes(res.status)) {
        commit('setRestricoesAssociados', []);
        commit('setTotalizadorRestritos', 0);
      }
      commit('switchCarregando', false);
      resolve();
    });
  },

  fetchConsumerProfile({ commit }, { cpf, telefone }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint = `/consumidor/v1/consumidores/${telefone}`;
      const response = await comunicacao.send(endpoint, { method: 'GET' });

      if ([200].includes(response.status)) {
        commit('setPerfilConsumidor', response.data.retorno);
        commit('switchCarregando', false);
        resolve({
          status: 200,
          msg: 'Perfil recuperado com sucesso',
          retorno: { chavePrimaria: 'telefone', ...response.data.retorno },
        });
      } else {
        endpoint = `/consumidor/v1/consumidores/${cpf}`;
        const responseComCpf = await comunicacao.send(endpoint, { method: 'GET' });

        if ([200].includes(responseComCpf.status)) {
          commit('setPerfilConsumidor', response.data.retorno);
          commit('switchCarregando', false);
          resolve({
            status: 200,
            msg: 'Perfil recuperado com sucesso',
            retorno: { chavePrimaria: 'cpf', ...responseComCpf.data.retorno },
          });
        } else {
          commit('setPerfilConsumidor', null);
          commit('switchCarregando', false);
          resolve({
            status: responseComCpf.status || 500,
            msg:
              responseComCpf.data.mensagem ||
              'Ops, ocorreu um erro desconhecido durante sua requisição! Tente novamente mais tarde.',
          });
        }
      }
    });
  },

  fetchConsumerOnNotification({ commit }, { telefone }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint = `/notificacao/v1/consumidores/1/1?telefoneContato=${telefone}`;
      const response = await comunicacao.send(endpoint, { method: 'GET' });

      if ([200].includes(response.status)) {
        commit('setPerfilConsumidor', response.data.retorno.consumidores[0]);
        commit('switchCarregando', false);
        resolve({
          status: 200,
          msg: 'Perfil recuperado com sucesso',
          retorno: { chavePrimaria: 'telefone', ...response.data.retorno.consumidores[0] },
        });
      } else {
        commit('setPerfilConsumidor', null);
        commit('switchCarregando', false);
        resolve({
          status: response.status || 500,
          msg:
            response.data.mensagem ||
            'Ops, ocorreu um erro desconhecido durante sua requisição! Tente novamente mais tarde.',
        });
      }
    });
  },

  atualizarPerfilConsumidor(_, { profile }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint = `/consumidor/v1/consumidores`;
      const response = await comunicacao.send(endpoint, { method: 'POST', data: { ...profile } });

      if (response.status == 200) {
        resolve({ status: 200, msg: 'Perfil do consumidor foi atualizado com sucesso' });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
          retorno: response.data.retorno,
        });
      }
    });
  },

  salvarConsumidor(_, { consumidor }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/consumidor/v1/consumidores`;
      const response = await comunicacao.send(endpoint, { method: 'POST', data: { ...consumidor } });

      if ([200, 201].includes(response.status)) {
        resolve({ status: response.status, mensagem: 'Perfil do consumidor salvo com sucesso!' });
      } else {
        resolve({
          status: response.status || 500,
          mensagem: response.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
          retorno: response.data.retorno,
        });
      }
    });
  },

  trocarSenhaConsumidor(_, { usuario, novaSenha, novaSenhaConfirmacao }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint = '/login/v1/logins/trocarSenhaAdmin';
      const response = await comunicacao.send(endpoint, {
        method: 'PATCH',
        data: { usuario, novaSenha, novaSenhaConfirmacao },
      });

      if (response.status == 200) {
        resolve({ status: 200, msg: 'Senha alterada com sucesso' });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setAceites(state, data) {
    state.aceites = data;
  },

  setTotalizadorAceites(state, data) {
    state.totalAceites = data;
  },

  setBairros(state, data) {
    state.bairros = data;
  },

  setConsumidores(state, data) {
    state.consumidores = data;
  },

  setPerfilConsumidor(state, data) {
    state.perfilConsumidor = data;
  },

  setTotalizador(state, data) {
    state.totalConsumidores = data;
  },

  setRestricoesAssociados(state, data) {
    state.restricoesAssociados = data;
  },

  setTotalizadorRestritos(state, data) {
    state.totalRestritos = data;
  },

  switchCarregando(state, data) {
    state.carregando = data;
  },

  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
