import Vue from 'vue';

const initialState = () => ({
  premios: [],
  loading: false,
});

const state = initialState();

const getters = {
  getPremios(state) {
    return state.premios;
  },

  getLoadingState(state) {
    return state.loading;
  },
};

const actions = {
  fetchPremios({ commit }, { campanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremio/${campanha.ano}/${
        campanha.identificacao
      }`;

      commit('setLoadingState', true);

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if (res.status == 200) {
        commit('setLoadingState', false);
        commit('setPremios', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de prêmios recuperada com sucesso',
          premios: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        commit('setPremios', []);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter a lista de prêmios',
        });
      }
    });
  },

  adicionarPremio({ commit }, { campanha, codigo, descricao, valor, cashback, configuracaoCashback, urlImagemPremio }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremio`;

      commit('setLoadingState', true);
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          codigo,
          descricao,
          valor,
          cashback,
          configuracaoCashback,
          urlImagemPremio,
        },
      });

      if (res.status == 201) {
        commit('setLoadingState', false);
        resolve({
          status: 201,
          mensagem: 'Prêmio adicionado com sucesso',
          retorno: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem || 'Ocorreu um problema durante a requisição para adicionar o prêmio',
        });
      }
    });
  },

  editarPremio({ commit }, { id, descricao, valor, cashback, configuracaoCashback, urlImagemPremio }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremio`;

      commit('setLoadingState', true);
      const res = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: {
          id,
          descricao,
          valor,
          cashback,
          configuracaoCashback,
          urlImagemPremio,
        },
      });

      if (res.status == 200) {
        commit('setLoadingState', false);
        resolve({
          status: 200,
          mensagem: 'Prêmio alterado com sucesso',
          retorno: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem || 'Ocorreu um problema durante a requisição para alterar o prêmio',
        });
      }
    });
  },

  excluirPremio({ commit }, { id, campanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/tabelaPremio/${id}`;

      commit('setLoadingState', true);
      const res = await comunicacao.send(endpoint, {
        method: 'DELETE',
        data: {
          campanha
        },
      });

      if (res.status == 200) {
        commit('setLoadingState', false);
        resolve({
          status: 200,
          mensagem: 'Prêmio excluído com sucesso',
        });
      } else {
        commit('setLoadingState', false);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem || 'Ocorreu um problema durante a requisição para excluir o prêmio',
        });
      }
    });
  },
};

const mutations = {
  setLoadingState(state, data) {
    state.loading = data;
  },

  setPremios(state, data) {
    state.premios = data;
  },
};

export default { namespaced: true, state, getters, actions, mutations };
