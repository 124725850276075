<template>
  <div class="base-lista" :class="classLista">
    <div class="item titulo-lista d-flex align-items-center justify-content-between">
      <slot name="colunas" />
    </div>

    <div
      class="item d-flex align-items-center justify-content-between"
      v-for="(item, index) in dados"
      :class="{ inativo: item.ativa === false }"
      @click="selecionarItem(item, index)"
      :key="index"
    >
      <div v-if="indice" class="ordem d-flex align-items-center justify-content-center">
        {{ `${item.indice ? item.indice : index + 1}` }}
      </div>
      <slot :item="item" :index="index" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLista',

  props: {
    dados: {
      type: Array,
      required: true,
    },
    indice: {
      type: Boolean,
      default: false,
    },
    classLista: {
      type: String,
    },
    destacarAoSelecionar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      itemSelecionado: null,
    };
  },

  methods: {
    selecionarItem(item, index) {
      const campoIndexNormalizado = item.indice ? item.indice : index + 1;

      if (this.itemSelecionado === campoIndexNormalizado) {
        this.itemSelecionado = null;
        this.$emit('itemSelecionado', null);

        return;
      }

      this.itemSelecionado = campoIndexNormalizado;
      this.$emit('itemSelecionado', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.inativo {
  background-color: #eee !important;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25) !important;
}
.base-lista {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  > .item {
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0;
    cursor: pointer;
    transition: all 0.3s;
    word-break: break-all;

    p {
      color: #a74168;
      font-size: 1.05rem;
    }

    > .base-titulo {
      //width: 25%;
      min-width: 62px;
      position: relative;

      > .medalha {
        font-size: 2.25rem;
        margin-right: 1.25rem;
        color: #0093d8;
        width: 100%;
        text-align: center;
      }

      &::after {
        content: '';
        width: 0.5px;
        height: 25px;
        background-color: rgba(0, 0, 0, 0.05);
        right: 0;
        position: absolute;
      }
    }

    > .ordem {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      background-color: #025ca5;
      font-size: 0.825rem;
      color: #fff;
      font-weight: 600;
      position: absolute;
      left: -20px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }

    > .base-conteudo {
      position: relative;

      .texto-destacado {
        color: #025ca5;
        font-size: 1.25rem;
        font-weight: 600;
      }

      &::after {
        content: '';
        width: 0.5px;
        height: 25px;
        background-color: rgba(0, 0, 0, 0.05);
        right: 0;
        position: absolute;
      }
    }

    &.ordem-1 {
      .medalha {
        font-size: 2.55rem;
        color: #c4b000;
      }

      > .ordem {
        background-color: #c4b000;
      }
    }

    &.ordem-2 {
      .medalha {
        font-size: 2.45rem;
        color: #a2a2a2;
      }

      > .ordem {
        background-color: #a2a2a2;
      }
    }

    &.ordem-3 {
      .medalha {
        font-size: 2.3rem;
        color: #9c6d0e;
      }

      > .ordem {
        background-color: #9c6d0e;
      }
    }

    &.titulo-lista {
      background-color: transparent;
      box-shadow: unset;
      padding: 0 2rem 0 1.5rem;

      p {
        color: #000;
        opacity: 0.9;
        font-size: 0.75rem;
        font-weight: 500;
      }

      > .base-titulo {
        &::after {
          opacity: 0;
        }
      }

      &:hover {
        box-shadow: unset;
      }
    }

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    &.selecionado {
      box-shadow: 0 0 0 2px #0093d8, 0 0 8px #0093d8;
    }
  }
}

@media screen and (max-width: 1024px) {
  .base-lista {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    > .item {
      width: 100%;
      position: relative;
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
      padding: 0.25rem 0;
      cursor: pointer;
      transition: all 0.3s;

      p {
        color: #a74168;
        font-size: 0.875rem;
      }

      > .base-titulo {
        //width: 25%;
        min-width: 62px;
        position: relative;

        > .medalha {
          font-size: 2.25rem;
          margin-right: 1.25rem;
          color: #0093d8;
          width: 100%;
          text-align: center;
        }

        &::after {
          content: '';
          width: 0.5px;
          height: 25px;
          background-color: rgba(0, 0, 0, 0.05);
          right: 0;
          position: absolute;
        }
      }

      > .ordem {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background-color: #025ca5;
        font-size: 0.825rem;
        color: #fff;
        font-weight: 600;
        position: absolute;
        left: -20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }

      > .base-conteudo {
        position: relative;

        .texto-destacado {
          color: #025ca5;
          font-size: 1rem;
          font-weight: 600;
        }

        &::after {
          content: '';
          width: 0.5px;
          height: 25px;
          background-color: rgba(0, 0, 0, 0.05);
          right: 0;
          position: absolute;
        }
      }

      &.ordem-1 {
        .medalha {
          font-size: 2.55rem;
          color: #c4b000;
        }

        > .ordem {
          background-color: #c4b000;
        }
      }

      &.ordem-2 {
        .medalha {
          font-size: 2.45rem;
          color: #a2a2a2;
        }

        > .ordem {
          background-color: #a2a2a2;
        }
      }

      &.ordem-3 {
        .medalha {
          font-size: 2.3rem;
          color: #9c6d0e;
        }

        > .ordem {
          background-color: #9c6d0e;
        }
      }

      &.titulo-lista {
        background-color: transparent;
        box-shadow: unset;
        padding: 0;

        p {
          color: #000;
          opacity: 0.9;
          font-size: 0.75rem;
          font-weight: 500;
        }

        > .base-titulo {
          &::after {
            opacity: 0;
          }
        }

        &:hover {
          box-shadow: unset;
        }
      }

      &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }

      &.selecionado {
        box-shadow: 0 0 0 2px #0093d8, 0 0 8px #0093d8;
      }
    }
  }
}
</style>
