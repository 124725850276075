<template>
  <!-- Componente do layout argon do dashboard -->
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" @keydown.esc="toggleSidebar">
    <!-- Menu lateral do dashboard -->
    <side-bar id="sidebar" :background-color="sidebarBackground" :campanha="campanha ? campanha.identificacao : ''">
      <template slot="selecionarServico">
        <template v-if="menuSelecionarModuloAtivo">
          <div
            class="identificacao-modulo d-flex align-items-center justify-content-center"
            :class="{ dropdownAtivo: dropdownSelecionarModuloAberto }"
            @click="
              dropdownSelecionarModuloAberto
                ? (dropdownSelecionarModuloAberto = false)
                : (dropdownSelecionarModuloAberto = true)
            "
          >
            {{ retornaServicoAtivo }}
            <i class="fas fa-chevron-down" />

            <div class="dropdown">
              <div v-for="modulo of modulosAtivos" :key="modulo.nome" class="item" @click="toggleServico(modulo.id)">
                {{ modulo.nome }}
              </div>
            </div>
          </div>

          <!-- Divider -->
          <hr class="divisao-menu" />
        </template>
      </template>

      <template slot="links">
        <transition name="fade" mode="out-in">
          <component :is="menuAtivo" class="scroll-menu" />
        </transition>
      </template>

      <template slot="selecionarCampanha" v-if="servicoAtivo != 'CASHBACK' && menuSelecionarModuloAtivo">
        <div class="identificacao-campanha">
          <template v-if="campanha">
            <div
              class="item nome d-flex align-items-center justify-content-center text-uppercase"
              :class="{ dropdownAtivo: dropdownSelecionarCampanhaAberto }"
              v-if="campanhas.length > 1"
              @click="
                dropdownSelecionarCampanhaAberto
                  ? (dropdownSelecionarCampanhaAberto = false)
                  : (dropdownSelecionarCampanhaAberto = true)
              "
            >
              {{ campanha.identificacao }} <i class="fas fa-chevron-down"></i>

              <div class="dropdown">
                <div
                  class="item"
                  v-for="campanha in campanhas"
                  :key="campanha.identificacao"
                  @click="selecionarCampanha(campanha)"
                >
                  {{ campanha.identificacao }}
                </div>
              </div>
            </div>

            <div class="item nome d-flex align-items-center justify-content-center text-uppercase" v-else>
              {{ campanha.identificacao }}
            </div>
          </template>

          <div class="item tipo d-flex justify-content-center text-uppercase">CAMPANHA</div>
        </div>
      </template>

      <!-- <template slot="acessarLogs" v-if="menuAcessarLogs">
        <hr class="my-3" />

        <div class="identificacao-campanha"> 
          <router-link
            class="item link tipo d-flex justify-content-center text-uppercase"
            style="margin-top: 0"
            to="/logs"
          >
            LOGS
          </router-link>
        </div>
      </template> -->
    </side-bar>

    <!-- Página do dashboard -->
    <div id="main-content" class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- Conteúdo principal do dashboard -->
          <router-view :campanha="campanha" :ready="campanha ? true : false"></router-view>
          <!-- Fim do conteúdo principal do dashboard -->
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import ContentFooter from './ContentFooter.vue';
import DashboardNavbar from './DashboardNavbar.vue';
import MenuCRM from './Menus/CRM.vue';
import MenuCashback from './Menus/Cashback/index.vue';
import MenuCashbackLight from './Menus/CashbackLight/index.vue';
import MenuSorteios from './Menus/Sorteios/index.vue';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    MenuSorteios,
    MenuCashback,
    MenuCRM,
    MenuCashbackLight,
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      campanha: null,
      comunicacao: null,
      campanhas: [],
      configuracoes: null,
      servicoAtivo: null,
      menuAtivo: null,
      dropdownSelecionarCampanhaAberto: false,
      dropdownSelecionarModuloAberto: false,
      tipoUsuario: null,
    };
  },
  async mounted() {
    this.comunicacao = new this.$comunicacao();
    this.servicoAtivo = this.$servico.obterServicoAtivo();
    await this.carregarCampanhas();
  },
  async beforeMount() {
    // Carregar configurações.
    this.configuracoes = this.$storeConfig.configuracoes();
    this.tipoUsuario = this.$autenticacao.logado().tipo;

    //Se usuário for um lojista, e o cashback polgo/light estiver ativo, irá redirecionar o usuário para a home do módulo.
    if (this.verificaLojistaCashbackPolgo) {
      this.servicoAtivo = this.$servico.gravarServicoAtivo('CASHBACKLIGHT');

      const PERMISSOES_PAGINAS = this.configuracoes.PERMISSOES_PAGINAS;

      PERMISSOES_PAGINAS.forEach((permissao) => {
        if (permissao.tipo === 'lojista') {
          const caminhoRotaAtual = this.$route.path.replace('/', '');

          if (!permissao.paginas.includes(caminhoRotaAtual)) {
            this.$router.push({ path: '/cashbacklight' });
          }
        }
      });
    }
    // Verificação se ha campanha salva no local storage.
    if (this.$campanha.obterCampanhaAtual()) {
      this.campanha = this.$campanha.obterCampanhaAtual();
    } else {
      this.campanha = null;
    }

    this.toggleServico(this.servicoAtivo);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    toggleServico(servico) {
      if (!servico) return;

      const toggleMenu = (servicoAtivar, redirecionar = true) => {
        const servico = this.modulosAtivos.find((e) => e.id === servicoAtivar);
        this.menuAtivo = servico.menu;
        if (redirecionar) this.$router.push({ path: servico.rota });
      };

      if (this.servicoAtivo == servico) {
        toggleMenu(servico, false);
        return;
      } else {
        this.servicoAtivo = servico;
        toggleMenu(servico);
      }
    },

    selecionarCampanha: async function (campanha) {
      this.$campanha.gravarCampanhaAtual(campanha);
      location.reload();
    },

    async carregarCampanhas() {
      let endpoint = `/campanha/v1/campanhas/`;
      let resp = await this.comunicacao.send(endpoint);
      if (resp.status == 200) {
        let campanhasRecuperadas = resp.data.retorno;
        this.campanhas = campanhasRecuperadas;
      } else {
        this.erro = true;
        this.msgErro =
          resp.data && resp.data.mensagem
            ? resp.data.mensagem
            : 'Ocorreu um erro desconhecido durante sua requisição! Tente novamente.';
      }
    },
  },
  watch: {
    servicoAtivo(servico) {
      this.toggleServico(servico);
      this.$servico.gravarServicoAtivo(servico);
    },
  },
  computed: {
    menuSelecionarModuloAtivo() {
      const servicosAtivos = this.configuracoes.MODULOS_SERVICOS.map((servico) => {
        return {
          nome: servico.nome,
          ativo: servico.ativo,
        };
      });
      let exibir = true;

      if (servicosAtivos.length) {
        if (this.tipoUsuario === 'lojista') {
          const verificaServicoCashbackLight = servicosAtivos.some(
            (servico) => servico.nome === 'CASHBACKLIGHT' && servico.ativo
          );
          exibir = verificaServicoCashbackLight ? false : true;
        }
      } else {
        exibir = false;
      }

      return exibir;
    },

    menuAcessarLogs() {
      return ['root', 'admin'].includes(this.tipoUsuario);
    },

    // Verifica se o usuário logado é um lojista e se o módulo de cashback polgo/light está ativo.
    verificaLojistaCashbackPolgo() {
      return (
        this.tipoUsuario === 'lojista' &&
        this.configuracoes.MODULOS_SERVICOS.some(
          (modulo) => modulo.nome === 'CASHBACKLIGHT' && (modulo.ativo === 'true' || modulo.ativo === true)
        )
      );
    },

    modulosAtivos() {
      const servicos = {
        CASHBACK: {
          rota: '/cashback',
          menu: 'MenuCashback',
          nome: this.configuracoes.FIDELIDADE_VALOR_PONTOS ? 'PONTOS' : 'CASHBACK',
        },

        CRM: {
          rota: '/crm',
          menu: 'MenuCRM',
          nome: 'CRM',
        },

        SORTEIOS: {
          rota: '/dashboard',
          menu: 'MenuSorteios',
          nome: 'Promoções',
        },

        CASHBACKLIGHT: {
          rota: '/cashbacklight',
          menu: 'MenuCashbackLight',
          nome: 'Cashback Polgo',
        },
      };

      return this.configuracoes.MODULOS_SERVICOS.filter((e) => e.ativo == 'true' || e.ativo == true).map((modulo) => ({
        ...servicos[modulo.nome],
        id: modulo.nome,
      }));
    },

    retornaServicoAtivo() {
      if (this.servicoAtivo === 'CASHBACKLIGHT') return 'CASHBACK POLGO';
      if (this.servicoAtivo === 'SORTEIOS') return 'PROMOÇÕES';
      if (this.servicoAtivo === 'CASHBACK' && this.configuracoes.FIDELIDADE_VALOR_PONTOS) return 'PONTOS';
      return this.servicoAtivo;
    },
  },
};
</script>

<style lang="scss">
.scroll-menu {
  overflow: auto;
  max-height: 70vh;
}
.divisao-menu {
  margin-top: 50px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin-top: 100px;
  }
}
.user-select-none {
  user-select: none;
}
.sidebar-collapse {
  list-style: none;
  padding: 0;
  padding-left: 10px;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.notificacao {
  border: 2px solid #be4a66;
  border-radius: 10px;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.notificacao .text-right {
  color: #be4a66;
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.notificacao {
    margin: 0.45rem 1rem;
    padding: 0.2rem 0.5rem;
  }
}
.qtde-notificacao {
  height: 20px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #be4a66;
  border-radius: 20px;
  color: #fff;
  padding: 5px;
  margin: 0 -15px 0 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.identificacao-campanha {
  width: 100%;

  > .item {
    height: 41px;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    transition: all 0.2s;

    &.nome {
      color: rgba(0, 164, 255, 1);
      background-color: #f6f7fc;
      border-radius: 10px 10px 0 0;
      padding-bottom: 10px;
    }
    &.tipo {
      background: rgba(0, 164, 255, 1);
      color: #f6f7fc;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
      margin-top: -10px;
    }

    &.link:hover {
      background-color: #0e436e;
    }

    > .dropdown {
      width: 100%;
      position: absolute;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: #fff;
      pointer-events: none;
      bottom: 51px;
      opacity: 0;
      pointer-events: none;

      > .item {
        color: rgba(0, 164, 255, 1);
        cursor: pointer;
        transition: all 0.2s;
        padding: 5px 0;

        &:hover {
          background: rgba(0, 164, 255, 1);
          color: #fff;
        }

        &:first-child {
          border-radius: 10px 10px 0 0;
        }
        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 6px);
      }
    }

    > i {
      margin-left: 4px;
      transition: transform 0.3s ease;
    }

    &.dropdownAtivo {
      > .dropdown {
        opacity: 1;
        pointer-events: all;
        bottom: 61px;
      }

      > i {
        transform: rotate(180deg);
      }
    }
  }
}

.identificacao-modulo {
  min-width: 250px !important;
  width: 270px;
  background: rgba(0, 164, 255, 1);
  position: fixed;
  height: 45px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  margin-left: -1.5rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  > .dropdown {
    width: calc(100% - 3rem);
    position: absolute;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    pointer-events: none;
    top: 51px;
    opacity: 0;
    pointer-events: none;

    > .item {
      color: rgba(0, 164, 255, 1);
      cursor: pointer;
      transition: all 0.2s;
      padding: 5px 0;
      text-align: center;
      border-radius: 10px;
      font-size: 1rem;

      &:hover {
        background: rgba(0, 164, 255, 1);
        color: #fff;
        border-radius: 0px;
      }

      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: -8px;
      left: calc(50% - 6px);
    }
  }

  > i {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }

  &.dropdownAtivo {
    > .dropdown {
      opacity: 1;
      pointer-events: all;
      top: 61px;
      z-index: 9999;
    }

    > i {
      transform: rotate(180deg);
    }
  }
}
</style>
