export default [
  {
    titulo: 'Home',
    condicao: () => true,
    icone: 'fas fa-home',
    caminho: 'dashboard',
    paginas: [],
  },
  {
    titulo: 'Cadastro',
    condicao: () => true,
    icone: 'fas fa-plus',
    paginas: [
      {
        titulo: () => 'Lojas',
        condicao: (configuracoes) => configuracoes.MODULO_ADESOES,
        icone: 'fas fa-handshake',
        caminho: 'adesoes',
      },
      {
        titulo: () => 'Campanhas',
        condicao: (configuracoes) => configuracoes.MODULO_CAMPANHAS,
        icone: 'fas fa-shopping-bag',
        caminho: 'campanhas',
      },
      {
        titulo: () => 'Compras',
        condicao: (configuracoes) => configuracoes.MODULO_CADASTRO_COMPRAS,
        icone: 'ni ni-cart',
        caminho: 'compras',
      },
      {
        titulo: () => 'Consumidores',
        condicao: (configuracoes) => configuracoes.MODULO_CONSUMIDORES,
        icone: 'ni ni-single-02',
        caminho: 'consumidores',
      },
      {
        titulo: () => 'Controle de Metas',
        condicao: (configuracoes) => configuracoes.MODULO_METAS_CONTACORRENTE,
        icone: 'fas fa-bullseye',
        caminho: 'controleMetas',
      },
      {
        titulo: () => 'Indústrias',
        condicao: (configuracoes) => configuracoes.MODULO_INDUSTRIAS,
        icone: 'fas fa-industry',
        caminho: 'industrias',
      },
      {
        titulo: () => 'Redes',
        condicao: (configuracoes) => configuracoes.MODULO_REDES,
        icone: 'fas fa-igloo',
        caminho: 'redes',
      },
      {
        titulo: () => 'Logins',
        condicao: () => true,
        icone: 'fas fa-key',
        caminho: 'logins',
      },
      {
        titulo: () => 'Produtos',
        condicao: (configuracoes) => configuracoes.MODULO_PRODUTOS,
        icone: 'fas fa-box',
        caminho: 'produtos',
      },
      {
        titulo: () => 'Categorias',
        condicao: (configuracoes) => configuracoes.MODULO_PRODUTOS,
        icone: 'fas fa-cubes',
        caminho: 'categorias',
      },
      {
        titulo: () => 'Questionário',
        condicao: (configuracoes) => configuracoes.MODULO_QUESTIONARIO,
        icone: 'fas fa-question',
        caminho: 'questionario',
      },
      {
        titulo: () => 'Sorteios',
        condicao: (configuracoes) => configuracoes.MODULO_SORTEIOS,
        icone: 'fas fa-gifts',
        caminho: 'sorteios',
      },
      // {
      //   titulo: () => "Sorteios por aproximação",
      //   condicao: configuracoes => configuracoes.MODULO_SORTEIOS,
      //   icone: "fas fa-gifts",
      //   caminho: "sorteiosAproximacao"
      // },
      {
        titulo: () => 'Grupo Produtos PDV',
        condicao: (configuracoes) => configuracoes.MODULO_PDV,
        icone: 'fas fa-boxes',
        caminho: 'listaProdutos',
      },
      {
        titulo: () => 'Importar vendas',
        condicao: (configuracoes) => configuracoes.MODULO_CADASTRO_COMPRAS,
        icone: 'fas fa-cloud-upload-alt',
        caminho: 'importarVendas',
      },
      {
        titulo: () => 'Grupos',
        condicao: () => true,
        icone: 'fas fa-users',
        caminho: 'grupos',
      },
      {
        titulo: () => 'Aceites',
        condicao: () => true,
        icone: 'fas fa-file-contract',
        caminho: 'aceites',
      },
    ],
  },
  {
    titulo: 'Consultas',
    condicao: () => true,
    icone: 'fas fa-search',
    paginas: [
      {
        titulo: () => 'Cedulas',
        condicao: (configuracoes) => configuracoes.MODULO_QUESTIONARIO,
        icone: 'fas fa-comment-alt',
        caminho: 'cedulas',
      },
      {
        titulo: () => 'Cupons Escaneados',
        condicao: (configuracoes) => configuracoes.MODULO_CUPONS_ESCANEADOS,
        icone: 'fas fa-qrcode',
        caminho: 'cupons',
      },
      {
        titulo: () => 'Saldos',
        condicao: (configuracoes) => configuracoes.MODULO_LISTAR_SALDO,
        icone: 'fas fa-money-bill',
        caminho: 'listarSaldo',
      },
      {
        titulo: () => 'Ranking de lojas',
        condicao: (configuracoes) =>
          configuracoes.MODULO_CUPONS_ESCANEADOS &&
          configuracoes.MODULO_ADESOES &&
          !configuracoes.ESCONDER_RANKING_ASSOCIADOS,
        icone: 'fas fa-boxes',
        caminho: 'rankingAssociados',
      },
      {
        titulo: () => 'Ranking de produtos',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-receipt',
        caminho: 'rankingProdutos',
      },
      {
        titulo: () => 'Ranking de associados agrupados',
        condicao: (configuracoes) =>
          !configuracoes.ESCONDER_RANKING_ASSOCIADOS && configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-layer-group',
        caminho: 'rankingDocumentosFiscais',
      },
      {
        titulo: () => 'Votação de Associados',
        condicao: (configuracoes) => configuracoes.MODULO_VOTACAO_ASSOCIADOS && configuracoes.MODULO_ADESOES,
        icone: 'fas fa-vote-yea',
        caminho: 'rankingVotacao',
      },
      {
        titulo: (configuracoes) => (configuracoes.MUDAR_TEXTO_RASPADINHAS ? 'Giros' : 'Raspadinhas'),
        condicao: (configuracoes) => configuracoes.MODULO_RASPADINHA_ALTERNATIVA,
        icone: 'fas fa-award',
        caminho: 'raspadinhasAlternativas',
      },
      {
        titulo: () => 'Documentos Fiscais por Emitente',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS_EMITENTE,
        icone: 'fas fa-file-alt',
        caminho: 'docsFiscaisEmitente',
      },
      {
        titulo: () => 'Arquivos',
        condicao: (configuracoes) => configuracoes.MODULO_ARQUIVOS,
        icone: 'fas fa-file',
        caminho: 'arquivos',
      },
      {
        titulo: () => 'Resultado dos vendedores',
        condicao: () => true,
        icone: 'fas fa-user-check',
        caminho: 'resultadoVendedores',
      },
      {
        titulo: () => 'Vendas',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-file-invoice',
        caminho: 'documentosFiscais',
      },
    ],
  },
  {
    titulo: 'Documento',
    icone: 'fas fa-file',
    condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
    paginas: [
      {
        titulo: () => 'Documentos Fiscais Origem',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS && configuracoes.MODULO_DOC_FISCAL_ORIGEM,
        icone: 'fas fa-file-alt',
        caminho: 'documentosFiscaisOrigem',
      },
      {
        titulo: () => 'Documentos Fiscais Pendentes',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-file-powerpoint',
        caminho: 'documentosFiscaisPendentes',
      },
    ],
  },
  {
    titulo: 'Relatórios',
    icone: 'fas fa-chart-pie',
    condicao: (configuracoes) => configuracoes.MODULO_RELATORIOS || configuracoes.URL_BI_ADMIN != false,
    paginas: [
      {
        titulo: () => 'Relatório',
        condicao: (configuracoes) => configuracoes.MODULO_RELATORIOS,
        icone: 'fas fa-file-alt',
        caminho: 'relatorios',
      },
      {
        titulo: () => 'Dash',
        condicao: (configuracoes) => configuracoes.URL_BI_ADMIN,
        icone: 'fas fa-chart-bar',
        caminho: 'biAdmin',
      },
    ],
  },
  {
    titulo: 'Área de relacionamento',
    condicao: (configuracoes) => configuracoes.MODULO_CHAT,
    icone: 'fas fa-user-friends',
    paginas: [
      {
        titulo: () => 'Chat',
        condicao: () => true,
        icone: 'fas fa-comments',
        caminho: 'chat',
      },
    ],
  },
  {
    titulo: 'CRM',
    condicao: (configuracoes) => configuracoes.MODULO_PDV,
    icone: 'fas fa-boxes',
    paginas: [
      {
        titulo: () => 'Histórico',
        condicao: () => true,
        icone: 'fas fa-shopping-basket',
        caminho: 'listagemProdutos',
      },
    ],
  },
  {
    titulo: 'Configurações',
    condicao: (configuracoes) =>
      configuracoes.MODULO_CONFIG_CUPOM || configuracoes.MODULO_CONFIG_RASPADINHA_ALTERNATIVA,
    icone: 'fas fa-cog',
    paginas: [
      {
        titulo: () => 'Cupons',
        condicao: (configuracoes) => configuracoes.MODULO_CONFIG_CUPOM,
        icone: 'fas fa-cog',
        caminho: 'configCupom',
      },
      {
        titulo: () => 'Cupons por Produtos',
        condicao: (configuracoes) => configuracoes.MODULO_CONFIG_PRODUTO,
        icone: 'fas fa-cog',
        caminho: 'configProduto',
      },
      {
        titulo: () => 'Raspadinhas por Produtos',
        condicao: (configuracoes) => configuracoes.MODULO_CONFIG_RASPADINHA_ALTERNATIVA,
        icone: 'fas fa-cog',
        caminho: 'configCompraRaspadinha',
      },
      {
        titulo: () => 'Documentos Fiscais',
        condicao: (configuracoes) => configuracoes.MODULO_CONFIG_DOCS_FISCAIS,
        icone: 'fas fa-cog',
        caminho: 'configDocumentoFiscal',
      },
      {
        titulo: (configuracoes) => (configuracoes.MUDAR_TEXTO_RASPADINHAS ? 'Giros' : 'Raspadinhas'),
        condicao: (configuracoes) => configuracoes.MODULO_CONFIG_RASPADINHA_ALTERNATIVA,
        icone: 'fas fa-cog',
        caminho: 'configRaspadinha',
      },
      {
        titulo: () => 'Geração de Números da Sorte',
        condicao: () => true,
        icone: 'fas fa-cog',
        caminho: 'configuracoesNumerosDaSorte',
      },
      {
        titulo: () => 'Geração de Números Aleatórios',
        condicao: () => true,
        icone: 'fas fa-cog',
        caminho: 'configuracoesNumerosAleatorios',
      },
      {
        titulo: () => 'Prêmios Instantâneos',
        condicao: () => true,
        icone: 'fas fa-cog',
        caminho: 'cadastrarPremios',
      },
      {
        titulo: () => 'Tabela de Premiação',
        condicao: () => true,
        icone: 'fas fa-cog',
        caminho: 'premiacoesRaspadinhas',
      },
    ],
  },
  // {
  //   titulo: 'Notificação',
  //   condicao: () => true,
  //   icone: 'fas fa-bell',
  //   paginas: [
  //     {
  //       titulo: () => 'Configurar eventos',
  //       condicao: () => true,
  //       icone: 'fas fa-cog',
  //       caminho: 'configuracoesNotificacao',
  //     },
  //     {
  //       titulo: () => 'Enviar Notificação',
  //       condicao: () => true,
  //       icone: 'fas fa-paper-plane',
  //       caminho: 'enviarNotificacao',
  //     },
  //   ],
  // },
  {
    titulo: 'Ajuda',
    condicao: (configuracoes) => configuracoes.MODULO_EMAIL,
    icone: 'fas fa-question',
    paginas: [
      {
        titulo: () => 'Modelo de email',
        condicao: () => true,
        icone: 'fas fa-envelope',
        caminho: 'email',
      },
    ],
  },
  {
    titulo: 'Suporte',
    condicao: (configuracoes) => configuracoes.MODULO_SUPORTE || configuracoes.LINK_SAC != null,
    icone: 'fas fa-life-ring',
    paginas: [
      {
        titulo: () => 'SAC',
        condicao: (configuracoes) => configuracoes.LINK_SAC,
        icone: 'fas fa-headset',
        caminho: 'this.configuracoes.LINK_SAC',
      },
      {
        titulo: () => 'Suporte online',
        condicao: (configuracoes) => configuracoes.MODULO_SUPORTE,
        icone: 'fas fa-life-ring',
        caminho: 'suporte',
      },
    ],
  },
];
