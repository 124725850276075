import Vue from 'vue';

// Object initial state
const initialState = () => ({
  cupons: [],
  documentoFiscalCupons: [],
  usuarioCupons: [],
  totalCupons: 0,
  totalDocsFiscaisCupons: 0,
  totalCuponsAcelerados: 0,
  carregando: false,
  compra: [],
  pontos: [],
  totalConsumidoresQueEscanearamCupons: 0,
  consumidorCupom: {},
  linkDownload: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCupons(state) {
    return state.cupons;
  },
  getDocumentoFiscalCupons(state) {
    return state.documentoFiscalCupons;
  },
  getTotalDocsFiscaisCupons(state) {
    return state.totalDocsFiscaisCupons;
  },
  getTotalCuponsAcelerados(state) {
    return state.totalCuponsAcelerados;
  },
  getUsuarioCupons(state) {
    return state.usuarioCupons;
  },
  getTotalizador(state) {
    return state.totalCupons;
  },
  getLoader(state) {
    return state.carregando;
  },
  getTotalConsumidoresComCupons(state) {
    return state.totalConsumidoresQueEscanearamCupons;
  },
  getCompra(state) {
    return state.compra;
  },
  getPontos(state) {
    return state.pontos;
  },
  getConsumidorCupom(state) {
    return state.consumidorCupom;
  },
  getLinkDownload(state) {
    return state.linkDownload;
  },
  getCarregando(state) {
    return state.carregando;
  },
};

// Actions
const actions = {
  fetchCupons({ commit }, { campanha, filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}/listarPorCampanha`;

      var res = await comunicacao.send(url, {
        method: 'POST',
        data: { filtro: filtro },
      });
      if ([200, 201].includes(res.status)) {
        commit('setCupons', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({
          status: 200,
          campoData: res.data.retorno.retorno[0] && res.data.retorno.retorno[0].numeroDaSorte ? true : false,
        });
      } else {
        commit('setCupons', []);
        resolve({
          status: 500,
        });
      }
      commit('setLoader', false);
    });
  },

  fetchDocumentoFiscalCupons({ commit }, { paginacao, documentoFiscal }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/documentoFiscal/${documentoFiscal}/${paginacao.porPagina}/${paginacao.pagina}`;

      var res = await comunicacao.send(url, {
        method: 'GET',
      });
      if ([200, 201].includes(res.status)) {
        commit('setDocumentoFiscalCupons', res.data.retorno);
        commit('setTotalDocsFiscaisCupons', res.data.retorno.total);
        resolve({
          status: 200,
          mensagem: 'Lista de cupons do documento fiscal recuperada com sucesso',
        });
      } else {
        commit('setDocumentoFiscalCupons', []);

        commit('setTotalDocsFiscaisCupons', 0);
        resolve({
          status: 500,
        });
      }
      commit('setLoader', false);
    });
  },
  fetchUsuarioCupons({ commit }, { campanha, usuario, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/consumidor/${campanha.ano}/${campanha.identificacao}/${usuario}/${paginacao.porPagina}/${paginacao.pagina}`;

      var res = await comunicacao.send(url, {
        method: 'GET',
      });
      if ([200, 201].includes(res.status)) {
        commit('setUsuarioCupons', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de cupons do usuário recuperada com sucesso',
          retorno: res.data.retorno,
        });
      } else {
        commit('setUsuarioCupons', []);
        resolve({
          status: 500,
        });
      }
      commit('setLoader', false);
    });
  },
  fetchTotalCuponsAcelerados({ commit }, { campanha }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/cupom/v2/cupons/${campanha.ano}/${campanha.identificacao}/totalAceleradosEscaneados`;

      commit('setLoader', true);

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      // eslint-disable-next-line no-console
      console.log(res.data.retorno.retorno);

      if (res.status == 200) {
        commit('setLoader', false);
        commit('setTotalCuponsAcelerados', res.data.retorno.retorno);
        resolve({
          status: 200,
          mensagem: 'Total cupons acelerados recuperado com sucesso',
          cuponsAcelerados: res.data.retorno.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setTotalCuponsAcelerados', 0);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter a quantidade de cupons acelerados',
        });
      }
    });
  },
  fetchTotalConsumidoresComCupons({ commit }, { campanha }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/cupom/v2/cupons/totalConsumidoresQueEscanearamCupons/${campanha.ano}/${campanha.identificacao}`;

      commit('setLoader', true);

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if (res.status == 200) {
        commit('setLoader', false);
        commit('setTotalConsumidoresComCuponsEscaneados', res.data.retorno.totalConsumidoresQueEscanearamCupons);
        resolve({
          status: 200,
          mensagem: 'Total de consumidores com cupons cadastrados recuperado com sucesso',
          premios: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setTotalConsumidoresComCuponsEscaneados', 0);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter o número de consumidores com cupons cadastrados',
        });
      }
    });
  },

  obterCompra({ commit }, { documentoFiscalID }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `produto/v1/compras/${documentoFiscalID}`;

      const response = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if (response.data.status == 200) {
        commit('setCompra', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setCompra', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  obterPontos({ commit }, { campanha, lancamentoID }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `contaCorrente/v1/lancamentos/${campanha.ano}/${campanha.identificacao}/${lancamentoID}`;

      const response = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if (response.data.status == 200) {
        commit('setPontos', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setPontos', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  obterConsumidorCupom({ commit }, { telefone }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/consumidor/${telefone}`;
      const response = await comunicacao.send(url, {
        method: 'GET',
      });
      if (response.status == 200) {
        commit('setConsumidorCupom', response.data.retorno);
        commit('setLoader', false);
        resolve({
          status: 200,
          msg: 'Consumidor recuperado.',
          data: response.data.retorno,
        });
      } else {
        commit('setConsumidorCupom', []);
        commit('setLoader', false);
        resolve({
          status: 404,
          msg: 'Nenhum consumidor encontrado.',
          data: {},
        });
      }
    });
  },

  gerarNumerosAleatorios({ commit }, { campanha, urlApp, quantidadeNumeros, numeracaoInicial = 1 }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/gerarNumerosAleatorios/${campanha.ano}/${campanha.identificacao}`;

      const resposta = await comunicacao.send(
        url,
        {
          method: 'POST',
          data: {
            urlApp,
            quantidadeNumeros: Number(quantidadeNumeros),
            numeracaoInicial: Number(numeracaoInicial),
          },
        },
        120000
      );

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        commit('setLinkDownload', resposta.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Números gerados com sucesso!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  importarLotesCupom({ commit }, { campanha, loteParaImportacao }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/cupom/v2/cupons/lote`;

      const resposta = await comunicacao.send(
        url,
        {
          method: 'POST',
          data: {
            anoCampanha: campanha.ano,
            identificacaoCampanha: campanha.identificacao,
            loteParaImportacao: loteParaImportacao,
          },
        },
        120000
      );

      commit('setCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({
          status: 200,
          mensagem: 'Cupons importados com sucesso!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setCupons(state, data) {
    state.cupons = data;
  },
  setDocumentoFiscalCupons(state, data) {
    state.documentoFiscalCupons = data;
  },
  setTotalCuponsAcelerados(state, data) {
    state.totalCuponsAcelerados = data;
  },
  setTotalDocsFiscaisCupons(state, data) {
    state.totalDocsFiscaisCupons = data;
  },
  setUsuarioCupons(state, data) {
    state.usuarioCupons = data;
  },
  setLoader(state, data) {
    state.carregando = data;
  },
  setTotalizador(state, data) {
    state.totalCupons = data;
  },
  setCompra(state, data) {
    state.compra = data;
  },
  setPontos(state, data) {
    state.pontos = data;
  },
  setTotalConsumidoresComCuponsEscaneados(state, data) {
    state.totalConsumidoresQueEscanearamCupons = data;
  },
  setConsumidorCupom(state, data) {
    state.consumidorCupom = data;
  },
  setLinkDownload(state, data) {
    state.linkDownload = data;
  },
  setCarregando(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
