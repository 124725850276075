import '@/assets/vendor/nucleo/css/nucleo.css';
import '@/assets/scss/argon.scss';
import globalComponents from './globalComponents';
import globalDirectives from './globalDirectives';
import SidebarPlugin from '@/components/SidebarPlugin/index';
import NotificationPlugin from '@/components/NotificationPlugin/index';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate';
import 'sweetalert2/dist/sweetalert2.min.css';
import './globalFilters';
import VueBarcodeScanner from 'vue-barcode-scanner';
import { Autenticacao } from '@/lib/Autenticacao';
import { Crypto } from '@/lib/Crypto';
import { Chat } from '@/lib/Chat';
import { Campanha, StoreConfig, Servico, Colaborador } from '@/lib/Storage';
import { Comunicacao } from '@/lib/Comunicacao';
import { Datas, Endereco, Geo, Objetos, Texto, Numeros, Validations } from '@/lib/Helper';
import { ProgressPlugin } from 'bootstrap-vue';
import Papa from 'papaparse';
import Xlsx from 'xlsx';
import { FormFilePlugin } from 'bootstrap-vue';
import VueConfetti from 'vue-confetti';
import VueParticles from 'vue-particles';
import VueClipboard from 'vue-clipboard2';
import ZoomOnHover from 'vue-zoom-on-hover';

export default {
  install(Vue) {
    // Plugins.
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(Vuelidate);
    Vue.use(SidebarPlugin);
    Vue.use(NotificationPlugin);
    Vue.use(VueSweetalert2);
    Vue.use(VueBarcodeScanner);
    Vue.use(ProgressPlugin);
    Vue.use(FormFilePlugin);
    Vue.use(VueConfetti);
    Vue.use(VueParticles);
    Vue.use(VueClipboard);
    Vue.use(ZoomOnHover);

    // Libs.
    Vue.prototype.$datas = Datas;
    Vue.prototype.$objetos = Objetos;
    Vue.prototype.$texto = Texto;
    Vue.prototype.$numeros = Numeros;
    Vue.prototype.$validations = Validations;
    Vue.prototype.$geo = Geo;
    Vue.prototype.$endereco = Endereco;
    Vue.prototype.$campanha = Campanha;
    Vue.prototype.$servico = Servico;
    Vue.prototype.$colaborador = Colaborador;
    Vue.prototype.$storeConfig = StoreConfig;
    Vue.prototype.$comunicacao = Comunicacao.default;
    Vue.prototype.$autenticacao = Autenticacao.default;
    Vue.prototype.$crypto = Crypto.default;
    Vue.prototype.$chat = Chat.default;
    Vue.prototype.$papa = Papa;
    Vue.prototype.$XLSX = Xlsx;
  },
};
