<template>
  <component
    :is="tag"
    :class="[{ show: isOpen }, { dropdown: direction === 'down' }, { dropup: direction === 'up' }]"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    v-click-outside="closeDropDown"
  >
    <slot name="title">
      <div id="dropdown-title" @click="toggleDropDown">
        <a class="dropdown-toggle dropdown-toggle-dropdown nav-link nav-link-dropdown" data-toggle="dropdown">
          <i
            :class="iconClasses"
            class="d-flex align-items-center justify-content-center"
            style="content: none; font-size: 1rem"
          ></i>
        </a>
      </div>
    </slot>
    <ul
      class="dropdown-menu dropdown-menu-right"
      style="cursor: auto"
      ref="menu"
      :class="[{ 'dropdown-menu-right': position === 'right' }, { show: isOpen }]"
    >
      <slot></slot>
    </ul>
  </component>
</template>
<script>
export default {
  name: 'base-dropdown-v2',
  props: {
    direction: {
      type: String,
      default: 'down',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    iconClasses: {
      type: String,
      description: 'Icon for dropdown title',
    },
    position: {
      type: String,
      default: 'right',
      description: 'Position of dropdown menu (e.g right|left)',
    },
    tag: {
      type: String,
      default: 'li',
      description: 'Dropdown html tag (e.g div, li etc)',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    async toggleDropDown() {
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },

    async closeDropDown() {
      if (!this.isOpen) return;

      this.isOpen = false;
      this.$emit('change', this.isOpen);
    },
  },
};
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown-toggle-dropdown::after {
  content: none;
}

.nav-link-dropdown {
  padding: 0;
}

.dropdown-item:active > h3 {
  color: white;
}

a.dropdown-item:active {
  color: white !important;
}
</style>
