const CryptoJS = require('crypto-js');

class Crypto {
  constructor() {}

  static criptografar(text) {
    return CryptoJS.AES.encrypt(String(text), 'secretkeynscapi').toString();
  }

  static descriptografar(text) {
    let bytes = '';
    try {
      bytes = CryptoJS.AES.decrypt(String(text), 'secretkeynscapi').toString(CryptoJS.enc.Utf8);
    } catch (err) {
      console.error(err);
      return bytes;
    }
    return bytes;
  }

  static criptografarObject(textObject) {
    return CryptoJS.AES.encrypt(JSON.stringify(textObject), 'secretkeynsc').toString();
  }

  static descriptografarObject(textObject) {
    const bytes = CryptoJS.AES.decrypt(textObject, 'secretkeynsc');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}

export default Crypto;
